
#EventAccordion__wrapper
  lost-column 12/12
  background-color: #333333
#EventAccordion
  lost-center: 980px;

.EventAccordion
  lost-column 2/10 10 0
  height 100%
  @media (max-width: 500px)
    lost-column 10/10
  &.current
    lost-column 6/10 10 0
    @media (max-width: 500px)
      lost-column 10/10

    .EventAccordion__content
      lost-column 1.6/6 6 0
      background-color rgba(243,147,0,0.8)
      &:hover
        background-color #666666
      @media (max-width: 500px)
        lost-column 10/10 10 0


      .EventAccordion__date__monthYear
        color #000
      .EventAccordion__date__time
        color #000

    background-position center center
    background-size auto 100%

  &:nth-child(2)
    .EventAccordion__content
      background-color #333333
      &:hover
        background-color myOrange
  &:nth-child(3)
    .EventAccordion__content
      background-color #666666
      &:hover
        background-color myOrange

.EventAccordion__content
  position relative
  padding: 40px 20px
  height 100%
  min-height 400px
  @media (max-width: 500px)
    min-height auto
    padding:20px 0

  .EventAccordion__date__number
    text-align center
    font-size 60px
    color:white
  .EventAccordion__date__monthYear
    font-size 22px
    text-align center
    color #a9a9a9
    text-transform: uppercase
  .EventAccordion__date__time
    font-size 18px
    text-align center
    color #a9a9a9
    margin-top: 10px
  .EventAccordion__title
    font-size 20px
    margin-top: 40px
    text-align center
    font-weight 800
    color white
    text-transform: uppercase
    a
      color white
      outline none

  .EventAccordion__intro
    padding: 40px 0
    text-align center
    color black

#triangle-right
  top 220px
  right:-15px
  position:absolute;
  width 0;
  height 0;
  border-left 30px solid transparent;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  @media (max-width: 500px)
    display none

  &.orange
    border-top 30px solid rgba(243,147,0,0.8);

  &.darkgrey
    border-top 30px solid rgba(51,51,51,1);
    z-index 4

  &.lightgrey
    border-top 30px solid rgba(243,147,0,0.8);