body {
  font-family: "Comfortaa";
}
a {
  text-decoration: none;
  color: #000;
}
a:hover,
a.active {
  color: #f39300;
}
header {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  position: relative;
}
header:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
header:last-child {
  margin-right: 0;
}
header:nth-child(12n) {
  margin-right: 0;
}
header:nth-child(12n + 1) {
  clear: left;
}
header .headerImage {
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
}
header .headerImage:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
header .headerImage:last-child {
  margin-right: 0;
}
header .headerImage:nth-child(1n) {
  margin-right: 0;
}
header .headerImage:nth-child(1n + 1) {
  clear: left;
}
header .headerImage img {
  width: 100%;
  vertical-align: middle;
}
#logo {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  border-bottom: 1px solid #000;
}
#logo:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#logo:last-child {
  margin-right: 0;
}
#logo:nth-child(12n) {
  margin-right: 0;
}
#logo:nth-child(12n + 1) {
  clear: left;
}
#logo img {
  display: block;
  width: 300px;
  margin: 20px auto;
}
div.menu {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  position: relative;
}
div.menu:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
div.menu:last-child {
  margin-right: 0;
}
div.menu:nth-child(12n) {
  margin-right: 0;
}
div.menu:nth-child(12n + 1) {
  clear: left;
}
ul#menu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
@media (max-width: 500px) {
  ul#menu {
    border-bottom: 1px solid #000;
  }
}
ul#menu li {
  display: inline-block;
  text-align: center;
  padding: 10px 3%;
}
@media (max-width: 500px) {
  ul#menu li {
    display: block;
    width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
  }
  ul#menu li:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  ul#menu li:last-child {
    margin-right: 0;
  }
  ul#menu li:nth-child(1n) {
    margin-right: 0;
  }
  ul#menu li:nth-child(1n + 1) {
    clear: left;
  }
}
ul#menu li a {
  margin: 0;
  padding: 0;
  font-weight: 800;
  text-decoration: none;
  color: #666;
}
ul#menu li a:hover,
ul#menu li a.active {
  color: #f39300;
}
.text1__wrapper {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  background-size: 980px auto;
  background-position: center -200px;
  background-repeat: no-repeat;
}
.text1__wrapper:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.text1__wrapper:last-child {
  margin-right: 0;
}
.text1__wrapper:nth-child(12n) {
  margin-right: 0;
}
.text1__wrapper:nth-child(12n + 1) {
  clear: left;
}
@media (max-width: 500px) {
  .text1__wrapper {
    background-size: 0 0;
  }
}
.text1 {
  *zoom: 1;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 0 30px 0;
  position: relative;
}
.text1:before {
  content: '';
  display: table;
}
.text1:after {
  content: '';
  display: table;
  clear: both;
}
@media (max-width: 500px) {
  .text1 {
    padding: 50px 5px 5px 5px;
    width: calc(99.99% * 3/3 - (30px - 30px * 3/3));
  }
  .text1:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  .text1:last-child {
    margin-right: 0;
  }
  .text1:nth-child(3n) {
    margin-right: 0;
  }
  .text1:nth-child(3n + 1) {
    clear: left;
  }
}
@-moz-keyframes shrinkMenu {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.4);
  }
  70% {
    transform: scale(0.3);
  }
  80% {
    transform: scale(0.2);
  }
  90% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0);
  }
}
@-webkit-keyframes shrinkMenu {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.4);
  }
  70% {
    transform: scale(0.3);
  }
  80% {
    transform: scale(0.2);
  }
  90% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0);
  }
}
@-o-keyframes shrinkMenu {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.4);
  }
  70% {
    transform: scale(0.3);
  }
  80% {
    transform: scale(0.2);
  }
  90% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes shrinkMenu {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.4);
  }
  70% {
    transform: scale(0.3);
  }
  80% {
    transform: scale(0.2);
  }
  90% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0);
  }
}
#EventAccordion__wrapper {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  background-color: #333;
}
#EventAccordion__wrapper:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#EventAccordion__wrapper:last-child {
  margin-right: 0;
}
#EventAccordion__wrapper:nth-child(12n) {
  margin-right: 0;
}
#EventAccordion__wrapper:nth-child(12n + 1) {
  clear: left;
}
#EventAccordion {
  *zoom: 1;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}
#EventAccordion:before {
  content: '';
  display: table;
}
#EventAccordion:after {
  content: '';
  display: table;
  clear: both;
}
.EventAccordion {
  width: calc(99.999999% * 2/10);
  height: 100%;
}
.EventAccordion:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
.EventAccordion:last-child {
  margin-right: 0;
}
.EventAccordion:nth-child(10n) {
  margin-right: 0;
}
.EventAccordion:nth-child(10n + 1) {
  clear: left;
}
@media (max-width: 500px) {
  .EventAccordion {
    width: calc(99.99% * 10/10 - (30px - 30px * 10/10));
  }
  .EventAccordion:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  .EventAccordion:last-child {
    margin-right: 0;
  }
  .EventAccordion:nth-child(10n) {
    margin-right: 0;
  }
  .EventAccordion:nth-child(10n + 1) {
    clear: left;
  }
}
.EventAccordion.current {
  width: calc(99.999999% * 6/10);
  background-position: center center;
  background-size: auto 100%;
}
.EventAccordion.current:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
.EventAccordion.current:last-child {
  margin-right: 0;
}
.EventAccordion.current:nth-child(10n) {
  margin-right: 0;
}
.EventAccordion.current:nth-child(10n + 1) {
  clear: left;
}
@media (max-width: 500px) {
  .EventAccordion.current {
    width: calc(99.99% * 10/10 - (30px - 30px * 10/10));
  }
  .EventAccordion.current:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  .EventAccordion.current:last-child {
    margin-right: 0;
  }
  .EventAccordion.current:nth-child(10n) {
    margin-right: 0;
  }
  .EventAccordion.current:nth-child(10n + 1) {
    clear: left;
  }
}
.EventAccordion.current .EventAccordion__content {
  width: calc(99.999999% * 1.6/6);
  background-color: rgba(243,147,0,0.8);
}
.EventAccordion.current .EventAccordion__content:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
.EventAccordion.current .EventAccordion__content:last-child {
  margin-right: 0;
}
.EventAccordion.current .EventAccordion__content:nth-child(6n) {
  margin-right: 0;
}
.EventAccordion.current .EventAccordion__content:nth-child(6n + 1) {
  clear: left;
}
.EventAccordion.current .EventAccordion__content:hover {
  background-color: #666;
}
@media (max-width: 500px) {
  .EventAccordion.current .EventAccordion__content {
    width: calc(99.999999% * 10/10);
  }
  .EventAccordion.current .EventAccordion__content:nth-child(n) {
    float: left;
    margin-right: 0;
    clear: none;
  }
  .EventAccordion.current .EventAccordion__content:last-child {
    margin-right: 0;
  }
  .EventAccordion.current .EventAccordion__content:nth-child(10n) {
    margin-right: 0;
  }
  .EventAccordion.current .EventAccordion__content:nth-child(10n + 1) {
    clear: left;
  }
}
.EventAccordion.current .EventAccordion__content .EventAccordion__date__monthYear {
  color: #000;
}
.EventAccordion.current .EventAccordion__content .EventAccordion__date__time {
  color: #000;
}
.EventAccordion:nth-child(2) .EventAccordion__content {
  background-color: #333;
}
.EventAccordion:nth-child(2) .EventAccordion__content:hover {
  background-color: #f39300;
}
.EventAccordion:nth-child(3) .EventAccordion__content {
  background-color: #666;
}
.EventAccordion:nth-child(3) .EventAccordion__content:hover {
  background-color: #f39300;
}
.EventAccordion__content {
  position: relative;
  padding: 40px 20px;
  height: 100%;
  min-height: 400px;
}
@media (max-width: 500px) {
  .EventAccordion__content {
    min-height: auto;
    padding: 20px 0;
  }
}
.EventAccordion__content .EventAccordion__date__number {
  text-align: center;
  font-size: 60px;
  color: #fff;
}
.EventAccordion__content .EventAccordion__date__monthYear {
  font-size: 22px;
  text-align: center;
  color: #a9a9a9;
  text-transform: uppercase;
}
.EventAccordion__content .EventAccordion__date__time {
  font-size: 18px;
  text-align: center;
  color: #a9a9a9;
  margin-top: 10px;
}
.EventAccordion__content .EventAccordion__title {
  font-size: 20px;
  margin-top: 40px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
}
.EventAccordion__content .EventAccordion__title a {
  color: #fff;
  outline: none;
}
.EventAccordion__content .EventAccordion__intro {
  padding: 40px 0;
  text-align: center;
  color: #000;
}
#triangle-right {
  top: 220px;
  right: -15px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
@media (max-width: 500px) {
  #triangle-right {
    display: none;
  }
}
#triangle-right.orange {
  border-top: 30px solid rgba(243,147,0,0.8);
}
#triangle-right.darkgrey {
  border-top: 30px solid #333;
  z-index: 4;
}
#triangle-right.lightgrey {
  border-top: 30px solid rgba(243,147,0,0.8);
}
#calendarContainer {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  padding-top: 25px;
  padding-bottom: 50px;
  background-image: url("/images/piatto_min.jpg");
  background-position: center center;
  background-size: auto 1300px;
}
#calendarContainer:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#calendarContainer:last-child {
  margin-right: 0;
}
#calendarContainer:nth-child(12n) {
  margin-right: 0;
}
#calendarContainer:nth-child(12n + 1) {
  clear: left;
}
@media (max-width: 500px) {
  #calendarContainer {
    background-image: none;
  }
}
.Calendar {
  *zoom: 1;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.Calendar:before {
  content: '';
  display: table;
}
.Calendar:after {
  content: '';
  display: table;
  clear: both;
}
@media (max-width: 600px) {
  .Calendar {
    *zoom: 1;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .Calendar:before {
    content: '';
    display: table;
  }
  .Calendar:after {
    content: '';
    display: table;
    clear: both;
  }
}
.Calendar.inTheHeading {
  top: 80px;
  *zoom: 1;
  max-width: 45%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.Calendar.inTheHeading:before {
  content: '';
  display: table;
}
.Calendar.inTheHeading:after {
  content: '';
  display: table;
  clear: both;
}
@media (max-width: 600px) {
  .Calendar.inTheHeading {
    *zoom: 1;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .Calendar.inTheHeading:before {
    content: '';
    display: table;
  }
  .Calendar.inTheHeading:after {
    content: '';
    display: table;
    clear: both;
  }
}
.Calendar__title {
  width: calc(99.99% * 7/7 - (30px - 30px * 7/7));
  text-align: center;
  font-size: 30px;
  padding-bottom: 25px;
}
.Calendar__title:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__title:last-child {
  margin-right: 0;
}
.Calendar__title:nth-child(7n) {
  margin-right: 0;
}
.Calendar__title:nth-child(7n + 1) {
  clear: left;
}
@media (max-width: 600px) {
  .Calendar__title {
    font-size: 20px;
  }
}
.Calendar__heading {
  width: calc(99.99% * 7/7 - (30px - 30px * 7/7));
}
.Calendar__heading:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__heading:last-child {
  margin-right: 0;
}
.Calendar__heading:nth-child(7n) {
  margin-right: 0;
}
.Calendar__heading:nth-child(7n + 1) {
  clear: left;
}
#Calendar__list {
  list-style: none;
  padding: 0;
  margin: 0;
}
#Calendar__list a:hover .Calendar__list_title {
  color: #f39300;
}
.Calendar__list_element {
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
  cursor: pointer;
  padding-bottom: 15px;
}
.Calendar__list_element:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__list_element:last-child {
  margin-right: 0;
}
.Calendar__list_element:nth-child(1n) {
  margin-right: 0;
}
.Calendar__list_element:nth-child(1n + 1) {
  clear: left;
}
.Calendar__list_date {
  color: #666;
  font-weight: 800;
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
}
.Calendar__list_date:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__list_date:last-child {
  margin-right: 0;
}
.Calendar__list_date:nth-child(1n) {
  margin-right: 0;
}
.Calendar__list_date:nth-child(1n + 1) {
  clear: left;
}
.Calendar__list__info,
.Calendar__list_title {
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
  color: #000;
  font-weight: 800;
}
.Calendar__list__info:nth-child(n),
.Calendar__list_title:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__list__info:last-child,
.Calendar__list_title:last-child {
  margin-right: 0;
}
.Calendar__list__info:nth-child(1n),
.Calendar__list_title:nth-child(1n) {
  margin-right: 0;
}
.Calendar__list__info:nth-child(1n + 1),
.Calendar__list_title:nth-child(1n + 1) {
  clear: left;
}
.Calendar__list_intro {
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
  color: #333;
  font-weight: 400;
}
.Calendar__list_intro:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__list_intro:last-child {
  margin-right: 0;
}
.Calendar__list_intro:nth-child(1n) {
  margin-right: 0;
}
.Calendar__list_intro:nth-child(1n + 1) {
  clear: left;
}
.Calendar__body {
  width: calc(99.99% * 7/7 - (30px - 30px * 7/7));
}
.Calendar__body:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__body:last-child {
  margin-right: 0;
}
.Calendar__body:nth-child(7n) {
  margin-right: 0;
}
.Calendar__body:nth-child(7n + 1) {
  clear: left;
}
.Calendar__monthYear {
  float: left;
  width: calc(99.99% * 5/7 - (30px - 30px * 5/7));
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 10px;
}
.Calendar__monthYear:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__monthYear:last-child {
  margin-right: 0;
}
.Calendar__monthYear:nth-child(7n) {
  margin-right: 0;
}
.Calendar__monthYear:nth-child(7n + 1) {
  clear: left;
}
@media (max-width: 600px) {
  .Calendar__monthYear {
    font-size: 20px;
  }
}
.Calendar__buttons {
  float: left;
  width: calc(99.99% * 2/7 - (30px - 30px * 2/7));
}
.Calendar__buttons:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__buttons:last-child {
  margin-right: 0;
}
.Calendar__buttons:nth-child(7n) {
  margin-right: 0;
}
.Calendar__buttons:nth-child(7n + 1) {
  clear: left;
}
.Calendar__button {
  float: left;
  width: calc(99.99% * 1/2 - (30px - 30px * 1/2));
  font-size: 40px;
  text-align: center;
  cursor: pointer;
}
.Calendar__button:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__button:last-child {
  margin-right: 0;
}
.Calendar__button:nth-child(2n) {
  margin-right: 0;
}
.Calendar__button:nth-child(2n + 1) {
  clear: left;
}
.Calendar__button:hover {
  color: #f39300;
}
@media (max-width: 600px) {
  .Calendar__button {
    font-size: 20px;
  }
}
.Calendar__day {
  float: left;
  position: relative;
  width: calc(99.999999% * 1/7);
  height: calc(99.999999% * 1/7);
  text-align: center;
  line-height: 70px;
  font-size: 45px;
}
.Calendar__day:nth-child(n) {
  float: left;
  margin-right: 0;
  margin-bottom: 0;
  clear: none;
}
.Calendar__day:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
.Calendar__day:nth-child(7n) {
  margin-right: 0;
}
.Calendar__day:nth-child(7n + 1) {
  clear: left;
}
.Calendar__day:nth-last-child(-n + 7) {
  margin-bottom: 0;
}
.Calendar__day span {
  color: #666;
}
.Calendar__day span.gotEvents {
  color: #000;
}
.Calendar__day span.today {
  color: #f39300;
}
@media (max-width: 600px) {
  .Calendar__day {
    font-size: 30px;
    line-height: 50px;
  }
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day:nth-child(i) {
  border-bottom: 1px solid #000;
}
.Calendar__day.heading {
  font-size: 20px;
  padding: 1rem 0;
  line-height: 20px;
  border-bottom-width: 0;
  border-top: 1px solid #000;
}
#list,
#search {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  left: -15%;
  background-image: url("/images/calendarButtons.png");
  background-size: 100% auto;
}
#list {
  top: 130px;
  background-position: 0 0;
  cursor: pointer;
}
#grid {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  left: -15%;
  background-image: url("/images/calendario.png");
  background-size: 100% auto;
  top: 130px;
  background-position: 0 0;
  cursor: pointer;
}
#search {
  top: 190px;
  background-position: 0 100%;
}
.Calendar__day__info__item {
  padding: 10px 0;
  border-top: 1px solid #000;
}
.Calendar__day__info__item:first-child {
  border-top-width: 0;
}
.Calendar__day__info {
  display: none;
  width: calc(99.99% * 4/2 - (30px - 30px * 4/2));
  position: absolute;
  background-color: #fff;
  padding: 10px;
  top: 0;
  left: 70px;
  z-index: 10;
}
.Calendar__day__info:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.Calendar__day__info:last-child {
  margin-right: 0;
}
.Calendar__day__info:nth-child(2n) {
  margin-right: 0;
}
.Calendar__day__info:nth-child(2n + 1) {
  clear: left;
}
.Calendar__day__info span {
  text-align: left;
  padding: 0;
  display: block;
  line-height: 20px;
}
#triangle-left {
  top: 30px;
  left: -10px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  -ms-transform: rotate(-135deg); /* IE 9 */
  -webkit-transform: rotate(-135deg); /* Chrome, Safari, Opera */
  transform: rotate(-135deg);
  border-top: 20px solid #fff;
}
.Calendar__day__info__category {
  text-transform: uppercase;
  font-size: 13px;
}
.Calendar__day__info__title {
  font-size: 18px;
  color: #f39300;
  font-size: 800;
}
.Calendar__day__info__datetime {
  font-size: 14px;
  font-size: 800;
}
footer {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  margin: 50px 0 0 0;
}
footer:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
footer:last-child {
  margin-right: 0;
}
footer:nth-child(12n) {
  margin-right: 0;
}
footer:nth-child(12n + 1) {
  clear: left;
}
#footer_container {
  *zoom: 1;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 20px;
}
#footer_container:before {
  content: '';
  display: table;
}
#footer_container:after {
  content: '';
  display: table;
  clear: both;
}
#footer1 {
  width: calc(99.999999% * 5/12);
}
#footer1:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
#footer1:last-child {
  margin-right: 0;
}
#footer1:nth-child(12n) {
  margin-right: 0;
}
#footer1:nth-child(12n + 1) {
  clear: left;
}
#footer1 ul.nav {
  width: calc(99.999999% * 4/4);
  list-style: none;
  margin: 0;
  padding: 0;
}
#footer1 ul.nav:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
#footer1 ul.nav:last-child {
  margin-right: 0;
}
#footer1 ul.nav:nth-child(4n) {
  margin-right: 0;
}
#footer1 ul.nav:nth-child(4n + 1) {
  clear: left;
}
#footer1 ul.nav li {
  padding: 5px 5px 5px 100px;
  font-weight: 800;
}
@media (max-width: 500px) {
  #footer1 ul.nav li {
    padding: 5px;
  }
}
#footer1 .miniLogo {
  width: calc(99.99% * 2.5/5 - (30px - 30px * 2.5/5));
  margin-top: 80px;
}
#footer1 .miniLogo:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#footer1 .miniLogo:last-child {
  margin-right: 0;
}
#footer1 .miniLogo:nth-child(5n) {
  margin-right: 0;
}
#footer1 .miniLogo:nth-child(5n + 1) {
  clear: left;
}
@media (max-width: 500px) {
  #footer1 .miniLogo {
    width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
    text-align: center;
  }
  #footer1 .miniLogo:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  #footer1 .miniLogo:last-child {
    margin-right: 0;
  }
  #footer1 .miniLogo:nth-child(4n) {
    margin-right: 0;
  }
  #footer1 .miniLogo:nth-child(4n + 1) {
    clear: left;
  }
}
#footer1 .miniLogo img {
  width: 100%;
}
@media (max-width: 500px) {
  #footer1 .miniLogo img {
    width: 50%;
  }
}
@media (max-width: 500px) {
  #footer1 {
    width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
    text-align: center;
  }
  #footer1:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  #footer1:last-child {
    margin-right: 0;
  }
  #footer1:nth-child(4n) {
    margin-right: 0;
  }
  #footer1:nth-child(4n + 1) {
    clear: left;
  }
}
#footer2 {
  width: calc(99.999999% * 4/12);
}
#footer2:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
#footer2:last-child {
  margin-right: 0;
}
#footer2:nth-child(12n) {
  margin-right: 0;
}
#footer2:nth-child(12n + 1) {
  clear: left;
}
#footer2 .h1,
#footer2 .h2 {
  width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
}
#footer2 .h1:nth-child(n),
#footer2 .h2:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#footer2 .h1:last-child,
#footer2 .h2:last-child {
  margin-right: 0;
}
#footer2 .h1:nth-child(4n),
#footer2 .h2:nth-child(4n) {
  margin-right: 0;
}
#footer2 .h1:nth-child(4n + 1),
#footer2 .h2:nth-child(4n + 1) {
  clear: left;
}
#footer2 .h2 {
  margin-bottom: 0;
}
#footer2 .p {
  width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
  margin-bottom: 20px;
}
#footer2 .p:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#footer2 .p:last-child {
  margin-right: 0;
}
#footer2 .p:nth-child(4n) {
  margin-right: 0;
}
#footer2 .p:nth-child(4n + 1) {
  clear: left;
}
@media (max-width: 500px) {
  #footer2 {
    width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
    text-align: center;
  }
  #footer2:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  #footer2:last-child {
    margin-right: 0;
  }
  #footer2:nth-child(4n) {
    margin-right: 0;
  }
  #footer2:nth-child(4n + 1) {
    clear: left;
  }
}
#footer3 {
  width: calc(99.999999% * 3/12);
}
#footer3:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
#footer3:last-child {
  margin-right: 0;
}
#footer3:nth-child(12n) {
  margin-right: 0;
}
#footer3:nth-child(12n + 1) {
  clear: left;
}
#footer3 .h1,
#footer3 .h2 {
  width: calc(99.99% * 3/3 - (30px - 30px * 3/3));
}
#footer3 .h1:nth-child(n),
#footer3 .h2:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#footer3 .h1:last-child,
#footer3 .h2:last-child {
  margin-right: 0;
}
#footer3 .h1:nth-child(3n),
#footer3 .h2:nth-child(3n) {
  margin-right: 0;
}
#footer3 .h1:nth-child(3n + 1),
#footer3 .h2:nth-child(3n + 1) {
  clear: left;
}
#footer3 .h2 {
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  #footer3 {
    width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
    text-align: center;
  }
  #footer3:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  #footer3:last-child {
    margin-right: 0;
  }
  #footer3:nth-child(4n) {
    margin-right: 0;
  }
  #footer3:nth-child(4n + 1) {
    clear: left;
  }
}
.p,
i {
  margin-bottom: 20px;
}
.h1 {
  font-weight: 800;
}
.h2 {
  font-weight: 800;
}
#facebook {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-image: url("/images/footerButtons.png");
  background-size: 100% auto;
  background-position: 0 0;
  margin: 10px 0;
}
#list {
  top: 130px;
}
#search {
  top: 190px;
  background-position: 0 100%;
}
#email {
  width: calc(99.999999% * 3.5/4);
  margin: 10px 0;
  padding: 5px;
}
#email:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
#email:last-child {
  margin-right: 0;
}
#email:nth-child(4n) {
  margin-right: 0;
}
#email:nth-child(4n + 1) {
  clear: left;
}
.subFooter {
  border-top: 1px solid #000;
  width: calc(99.99% * 7/7 - (30px - 30px * 7/7));
}
.subFooter:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
.subFooter:last-child {
  margin-right: 0;
}
.subFooter:nth-child(7n) {
  margin-right: 0;
}
.subFooter:nth-child(7n + 1) {
  clear: left;
}
.subFooter img {
  display: block;
  width: 200px;
  margin: 20px auto;
}
#Quoted {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  background-color: #5b5b5b;
}
#Quoted:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Quoted:last-child {
  margin-right: 0;
}
#Quoted:nth-child(12n) {
  margin-right: 0;
}
#Quoted:nth-child(12n + 1) {
  clear: left;
}
#Quoted__content {
  *zoom: 1;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  position: relative;
  quotes: "‘" "’" "“" "”";
}
#Quoted__content:before {
  content: '';
  display: table;
}
#Quoted__content:after {
  content: '';
  display: table;
  clear: both;
}
@media (max-width: 600px) {
  #Quoted__content {
    padding: 35px;
  }
}
#Quoted__content:before {
  font-family: Times;
  content: open-quote;
  color: #fff;
  font-size: 200px;
  line-height: 200px;
  position: absolute;
  left: -20px;
}
#Quoted__content:after {
  font-family: Times;
  content: close-quote;
  color: #fff;
  font-size: 200px;
  line-height: 200px;
  display: block;
  position: absolute;
  bottom: -85px;
  right: -20px;
}
p.quoted {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 30px 0;
}
#event {
  *zoom: 1;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}
#event:before {
  content: '';
  display: table;
}
#event:after {
  content: '';
  display: table;
  clear: both;
}
#event__title {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  color: #f39300;
  padding-top: 40px;
}
#event__title:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__title:last-child {
  margin-right: 0;
}
#event__title:nth-child(12n) {
  margin-right: 0;
}
#event__title:nth-child(12n + 1) {
  clear: left;
}
#event__image {
  margin-top: 20px;
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
}
#event__image:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__image:last-child {
  margin-right: 0;
}
#event__image:nth-child(12n) {
  margin-right: 0;
}
#event__image:nth-child(12n + 1) {
  clear: left;
}
#event__image img {
  width: 100%;
}
#event__intro {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  text-align: center;
  padding: 40px 0;
  font-weight: 800;
  font-size: 16px;
}
#event__intro:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__intro:last-child {
  margin-right: 0;
}
#event__intro:nth-child(12n) {
  margin-right: 0;
}
#event__intro:nth-child(12n + 1) {
  clear: left;
}
#event__content {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  font-size: 14px;
}
#event__content:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__content:last-child {
  margin-right: 0;
}
#event__content:nth-child(12n) {
  margin-right: 0;
}
#event__content:nth-child(12n + 1) {
  clear: left;
}
#event__sidebar {
  padding: 20px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  width: calc(99.99% * 3/12 - (30px - 30px * 3/12));
  float: left;
  text-align: center;
}
#event__sidebar:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__sidebar:last-child {
  margin-right: 0;
}
#event__sidebar:nth-child(12n) {
  margin-right: 0;
}
#event__sidebar:nth-child(12n + 1) {
  clear: left;
}
#event__sidebar__day {
  font-size: 50px;
  color: #f39300;
  display: block;
}
#event__sidebar__monthYear {
  text-transform: uppercase;
  text-align: center;
  width: calc(99.99% * 3/3 - (30px - 30px * 3/3));
}
#event__sidebar__monthYear:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__sidebar__monthYear:last-child {
  margin-right: 0;
}
#event__sidebar__monthYear:nth-child(3n) {
  margin-right: 0;
}
#event__sidebar__monthYear:nth-child(3n + 1) {
  clear: left;
}
#event__sidebar__time {
  text-align: center;
  width: calc(99.99% * 3/3 - (30px - 30px * 3/3));
}
#event__sidebar__time:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__sidebar__time:last-child {
  margin-right: 0;
}
#event__sidebar__time:nth-child(3n) {
  margin-right: 0;
}
#event__sidebar__time:nth-child(3n + 1) {
  clear: left;
}
#event__sidebar__place {
  text-align: center;
  width: calc(99.99% * 3/3 - (30px - 30px * 3/3));
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
#event__sidebar__place:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__sidebar__place:last-child {
  margin-right: 0;
}
#event__sidebar__place:nth-child(3n) {
  margin-right: 0;
}
#event__sidebar__place:nth-child(3n + 1) {
  clear: left;
}
#event__sidebar__shareButtons__wrapper {
  *zoom: 1;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
#event__sidebar__shareButtons__wrapper:before {
  content: '';
  display: table;
}
#event__sidebar__shareButtons__wrapper:after {
  content: '';
  display: table;
  clear: both;
}
#event__sidebar__shareButtons {
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
}
#event__sidebar__shareButtons:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__sidebar__shareButtons:last-child {
  margin-right: 0;
}
#event__sidebar__shareButtons:nth-child(1n) {
  margin-right: 0;
}
#event__sidebar__shareButtons:nth-child(1n + 1) {
  clear: left;
}
#event__sidebar__shareButtons a {
  width: calc(99.99% * 1/2 - (5px - 5px * 1/2));
  height: calc(99.99% * 1/2 - (5px - 5px * 1/2));
}
#event__sidebar__shareButtons a:nth-child(n) {
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  clear: none;
}
#event__sidebar__shareButtons a:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
#event__sidebar__shareButtons a:nth-child(2n) {
  margin-right: 0;
}
#event__sidebar__shareButtons a:nth-child(2n + 1) {
  clear: left;
}
#event__sidebar__shareButtons a:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
#event__sidebar__shareButtons a img {
  width: 100%;
}
#event__text {
  width: calc(99.99% * 9/12 - (30px - 30px * 9/12));
  float: left;
}
#event__text:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__text:last-child {
  margin-right: 0;
}
#event__text:nth-child(12n) {
  margin-right: 0;
}
#event__text:nth-child(12n + 1) {
  clear: left;
}
#event__photos {
  margin-top: 20px;
  width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
}
#event__photos:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#event__photos:last-child {
  margin-right: 0;
}
#event__photos:nth-child(4n) {
  margin-right: 0;
}
#event__photos:nth-child(4n + 1) {
  clear: left;
}
#event__photos .thumb,
#event__photos .first {
  width: calc(99.99% * 1/4 - (5px - 5px * 1/4));
  height: calc(99.99% * 1/4 - (5px - 5px * 1/4));
  background-size: 300px auto;
  background-position: center center;
  height: 200px;
  float: left;
}
#event__photos .thumb:nth-child(n),
#event__photos .first:nth-child(n) {
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  clear: none;
}
#event__photos .thumb:last-child,
#event__photos .first:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
#event__photos .thumb:nth-child(4n),
#event__photos .first:nth-child(4n) {
  margin-right: 0;
}
#event__photos .thumb:nth-child(4n + 1),
#event__photos .first:nth-child(4n + 1) {
  clear: left;
}
#event__photos .thumb:nth-last-child(-n + 4),
#event__photos .first:nth-last-child(-n + 4) {
  margin-bottom: 0;
}
#event__photos .thumb .wrapper,
#event__photos .first .wrapper {
  height: 170px;
  display: block;
  opacity: 0;
  padding: 15px;
  background-color: #f39300;
}
#event__photos .thumb span,
#event__photos .first span {
  display: block;
}
#event__photos .thumb span.title,
#event__photos .first span.title {
  font-weight: 800;
}
#event__photos .first .wrapper {
  opacity: 1;
  background-color: #2d2d2d;
}
#event__photos .first .title {
  color: #f39300;
  text-shadow: none;
}
#video {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
}
#video:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#video:last-child {
  margin-right: 0;
}
#video:nth-child(12n) {
  margin-right: 0;
}
#video:nth-child(12n + 1) {
  clear: left;
}
#photoGallery__wrapper {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
}
#photoGallery__wrapper:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#photoGallery__wrapper:last-child {
  margin-right: 0;
}
#photoGallery__wrapper:nth-child(12n) {
  margin-right: 0;
}
#photoGallery__wrapper:nth-child(12n + 1) {
  clear: left;
}
#photoGallery {
  *zoom: 1;
  *zoom: 1;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  height: 100%;
  background-color: #333;
}
#photoGallery:before {
  content: '';
  display: table;
}
#photoGallery:after {
  content: '';
  display: table;
  clear: both;
}
#photoGallery:before {
  content: '';
  display: table;
}
#photoGallery:after {
  content: '';
  display: table;
  clear: both;
}
#photoGallery.first4 {
  padding: 30px 30px 5px 30px;
}
#photoGallery.last {
  padding: 5px 30px 30px 30px;
}
#photoGallery .photo,
#photoGallery .video {
  width: calc(99.99% * 1/4 - (10px - 10px * 1/4));
  height: calc(99.99% * 1/4 - (10px - 10px * 1/4));
  float: left;
  background-position: center center;
  background-size: auto 100%;
  height: 200px;
  cursor: pointer;
}
#photoGallery .photo:nth-child(n),
#photoGallery .video:nth-child(n) {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  clear: none;
}
#photoGallery .photo:last-child,
#photoGallery .video:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
#photoGallery .photo:nth-child(4n),
#photoGallery .video:nth-child(4n) {
  margin-right: 0;
}
#photoGallery .photo:nth-child(4n + 1),
#photoGallery .video:nth-child(4n + 1) {
  clear: left;
}
#photoGallery .photo:nth-last-child(-n + 4),
#photoGallery .video:nth-last-child(-n + 4) {
  margin-bottom: 0;
}
@media (max-width: 500px) {
  #photoGallery .photo,
  #photoGallery .video {
    width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
  }
  #photoGallery .photo:nth-child(n),
  #photoGallery .video:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  #photoGallery .photo:last-child,
  #photoGallery .video:last-child {
    margin-right: 0;
  }
  #photoGallery .photo:nth-child(4n),
  #photoGallery .video:nth-child(4n) {
    margin-right: 0;
  }
  #photoGallery .photo:nth-child(4n + 1),
  #photoGallery .video:nth-child(4n + 1) {
    clear: left;
  }
}
#viewer_wrapper_background {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
}
#viewer_wrapper_background:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#viewer_wrapper_background:last-child {
  margin-right: 0;
}
#viewer_wrapper_background:nth-child(12n) {
  margin-right: 0;
}
#viewer_wrapper_background:nth-child(12n + 1) {
  clear: left;
}
#viewer_wrapper {
  *zoom: 1;
  *zoom: 1;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  background-color: #333;
}
#viewer_wrapper:before {
  content: '';
  display: table;
}
#viewer_wrapper:after {
  content: '';
  display: table;
  clear: both;
}
#viewer_wrapper:before {
  content: '';
  display: table;
}
#viewer_wrapper:after {
  content: '';
  display: table;
  clear: both;
}
#viewer {
  *zoom: 1;
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
}
#viewer:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#viewer:last-child {
  margin-right: 0;
}
#viewer:nth-child(1n) {
  margin-right: 0;
}
#viewer:nth-child(1n + 1) {
  clear: left;
}
#viewer:before {
  content: '';
  display: table;
}
#viewer:after {
  content: '';
  display: table;
  clear: both;
}
#viewer .photoBig,
#viewer .videoBig {
  width: calc(99.99% * 4.5/7 - (30px - 30px * 4.5/7));
  float: left;
}
#viewer .photoBig:nth-child(n),
#viewer .videoBig:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#viewer .photoBig:last-child,
#viewer .videoBig:last-child {
  margin-right: 0;
}
#viewer .photoBig:nth-child(7n),
#viewer .videoBig:nth-child(7n) {
  margin-right: 0;
}
#viewer .photoBig:nth-child(7n + 1),
#viewer .videoBig:nth-child(7n + 1) {
  clear: left;
}
@media (max-width: 500px) {
  #viewer .photoBig,
  #viewer .videoBig {
    width: calc(99.99% * 7/7 - (30px - 30px * 7/7));
  }
  #viewer .photoBig:nth-child(n),
  #viewer .videoBig:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  #viewer .photoBig:last-child,
  #viewer .videoBig:last-child {
    margin-right: 0;
  }
  #viewer .photoBig:nth-child(7n),
  #viewer .videoBig:nth-child(7n) {
    margin-right: 0;
  }
  #viewer .photoBig:nth-child(7n + 1),
  #viewer .videoBig:nth-child(7n + 1) {
    clear: left;
  }
}
#viewer .photoBig img,
#viewer .videoBig img {
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
}
#viewer .photoBig img:nth-child(n),
#viewer .videoBig img:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#viewer .photoBig img:last-child,
#viewer .videoBig img:last-child {
  margin-right: 0;
}
#viewer .photoBig img:nth-child(1n),
#viewer .videoBig img:nth-child(1n) {
  margin-right: 0;
}
#viewer .photoBig img:nth-child(1n + 1),
#viewer .videoBig img:nth-child(1n + 1) {
  clear: left;
}
#viewer .description {
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  width: calc(99.99% * 2/7 - (30px - 30px * 2/7));
  float: left;
  padding-top: 15px;
}
#viewer .description:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#viewer .description:last-child {
  margin-right: 0;
}
#viewer .description:nth-child(7n) {
  margin-right: 0;
}
#viewer .description:nth-child(7n + 1) {
  clear: left;
}
@media (max-width: 500px) {
  #viewer .description {
    width: calc(99.99% * 7/7 - (30px - 30px * 7/7));
  }
  #viewer .description:nth-child(n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }
  #viewer .description:last-child {
    margin-right: 0;
  }
  #viewer .description:nth-child(7n) {
    margin-right: 0;
  }
  #viewer .description:nth-child(7n + 1) {
    clear: left;
  }
}
#viewer .description span {
  text-transform: uppercase;
  color: #999;
  font-size: 14px;
  display: block;
}
#viewer .description span.title,
#viewer .description span.date {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #fff;
}
#viewer .close {
  width: calc(99.99% * 0.5/7 - (30px - 30px * 0.5/7));
  float: left;
  color: #999;
  font-size: 30px;
  text-align: right;
  cursor: pointer;
}
#viewer .close:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#viewer .close:last-child {
  margin-right: 0;
}
#viewer .close:nth-child(7n) {
  margin-right: 0;
}
#viewer .close:nth-child(7n + 1) {
  clear: left;
}
#viewer .close:hover {
  color: #f39300;
}
#viewer .share,
#viewer .back {
  position: relative;
  border-top: 1px solid #999;
  padding: 15px 0;
  color: #999;
  font-size: 14px;
}
#viewer .share .symbol,
#viewer .back .symbol {
  position: relative;
  right: -50px;
  display: inline;
  color: #f39300;
  font-size: 35px;
  line-height: 0;
  margin-left: 10px;
  top: 5px;
  text-align: right;
}
#viewer .back i {
  position: absolute;
  right: 0;
  font-size: 20px;
  text-align: right;
  color: #f39300;
  margin-bottom: 0;
}
#viewer .back a {
  color: #999;
}
#viewer .back a:hover {
  color: #f39300;
}
#photoAlbum__wrapper {
  *zoom: 1;
  *zoom: 1;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}
#photoAlbum__wrapper:before {
  content: '';
  display: table;
}
#photoAlbum__wrapper:after {
  content: '';
  display: table;
  clear: both;
}
#photoAlbum__wrapper:before {
  content: '';
  display: table;
}
#photoAlbum__wrapper:after {
  content: '';
  display: table;
  clear: both;
}
#photoAlbum__wrapper span.title {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  font-size: 50px;
  text-align: center;
  padding: 20px 0 20px 0;
}
#photoAlbum__wrapper span.title:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#photoAlbum__wrapper span.title:last-child {
  margin-right: 0;
}
#photoAlbum__wrapper span.title:nth-child(12n) {
  margin-right: 0;
}
#photoAlbum__wrapper span.title:nth-child(12n + 1) {
  clear: left;
}
#photoAlbum__wrapper #photoAlbum {
  *zoom: 1;
  *zoom: 1;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
#photoAlbum__wrapper #photoAlbum:before {
  content: '';
  display: table;
}
#photoAlbum__wrapper #photoAlbum:after {
  content: '';
  display: table;
  clear: both;
}
#photoAlbum__wrapper #photoAlbum:before {
  content: '';
  display: table;
}
#photoAlbum__wrapper #photoAlbum:after {
  content: '';
  display: table;
  clear: both;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event {
  width: calc(99.99% * 1/4 - (20px - 20px * 1/4));
  height: calc(99.99% * 1/4 - (20px - 20px * 1/4));
  float: left;
  cursor: pointer;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event:nth-child(n) {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  clear: none;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event:nth-child(4n) {
  margin-right: 0;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event:nth-child(4n + 1) {
  clear: left;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event:nth-last-child(-n + 4) {
  margin-bottom: 0;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event:hover .photoAlbum__event__title a {
  color: #f39300;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event img {
  width: 100%;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__img {
  height: 150px;
  background-size: auto 100%;
  background-position: center center;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__title a {
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
  padding-top: 15px;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  color: #333;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__title a:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__title a:last-child {
  margin-right: 0;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__title a:nth-child(1n) {
  margin-right: 0;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__title a:nth-child(1n + 1) {
  clear: left;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__title a:hover {
  color: #f39300;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__date {
  width: calc(99.99% * 1/1 - (30px - 30px * 1/1));
  padding-top: 2px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #666;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__date:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__date:last-child {
  margin-right: 0;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__date:nth-child(1n) {
  margin-right: 0;
}
#photoAlbum__wrapper #photoAlbum .photoAlbum__event .photoAlbum__event__date:nth-child(1n + 1) {
  clear: left;
}
@font-face {
  font-family: 'Mono Social Icons Font';
  src: url("/fonts/MonoSocialIconsFont-1.10.eot");
  src: url("/fonts/MonoSocialIconsFont-1.10.eot?#iefix") format('embedded-opentype'), url("/fonts/MonoSocialIconsFont-1.10.woff") format('woff'), url("/fonts/MonoSocialIconsFont-1.10.ttf") format('truetype'), url("/fonts/MonoSocialIconsFont-1.10.svg#MonoSocialIconsFont") format('svg');
  src: url("/fonts/MonoSocialIconsFont-1.10.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
.symbol,
a.symbol:before {
  font-family: 'Mono Social Icons Font';
  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
a.symbol:before {
  content: attr(title);
  margin-right: 0.3em;
  font-size: 130%;
}
#Brochure__wrapper {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
}
#Brochure__wrapper:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__wrapper:last-child {
  margin-right: 0;
}
#Brochure__wrapper:nth-child(12n) {
  margin-right: 0;
}
#Brochure__wrapper:nth-child(12n + 1) {
  clear: left;
}
#Brochure {
  *zoom: 1;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}
#Brochure:before {
  content: '';
  display: table;
}
#Brochure:after {
  content: '';
  display: table;
  clear: both;
}
#Brochure__title {
  width: calc(99.99% * 12/12 - (30px - 30px * 12/12));
  text-align: center;
  font-size: 40px;
  margin-top: 50px;
  color: #f39300;
}
#Brochure__title:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__title:last-child {
  margin-right: 0;
}
#Brochure__title:nth-child(12n) {
  margin-right: 0;
}
#Brochure__title:nth-child(12n + 1) {
  clear: left;
}
#Brochure__1 {
  width: calc(99.99% * 4/12 - (30px - 30px * 4/12));
}
#Brochure__1:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__1:last-child {
  margin-right: 0;
}
#Brochure__1:nth-child(12n) {
  margin-right: 0;
}
#Brochure__1:nth-child(12n + 1) {
  clear: left;
}
#Brochure__1 img {
  border: 1px solid #000;
  width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
}
#Brochure__1 img:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__1 img:last-child {
  margin-right: 0;
}
#Brochure__1 img:nth-child(4n) {
  margin-right: 0;
}
#Brochure__1 img:nth-child(4n + 1) {
  clear: left;
}
#Brochure__2 {
  width: calc(99.99% * 4/12 - (30px - 30px * 4/12));
}
#Brochure__2:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__2:last-child {
  margin-right: 0;
}
#Brochure__2:nth-child(12n) {
  margin-right: 0;
}
#Brochure__2:nth-child(12n + 1) {
  clear: left;
}
#Brochure__2 img {
  width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
}
#Brochure__2 img:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__2 img:last-child {
  margin-right: 0;
}
#Brochure__2 img:nth-child(4n) {
  margin-right: 0;
}
#Brochure__2 img:nth-child(4n + 1) {
  clear: left;
}
#Brochure__3 {
  width: calc(99.99% * 4/12 - (30px - 30px * 4/12));
}
#Brochure__3:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__3:last-child {
  margin-right: 0;
}
#Brochure__3:nth-child(12n) {
  margin-right: 0;
}
#Brochure__3:nth-child(12n + 1) {
  clear: left;
}
#Brochure__downloadBtn {
  width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
  margin-bottom: 20px;
}
#Brochure__downloadBtn:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__downloadBtn:last-child {
  margin-right: 0;
}
#Brochure__downloadBtn:nth-child(4n) {
  margin-right: 0;
}
#Brochure__downloadBtn:nth-child(4n + 1) {
  clear: left;
}
#Brochure__downloadBtn img {
  display: block;
  width: 100px;
  margin: auto;
}
#Brochure__text {
  padding: 30px;
}
#Brochure__share {
  width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
}
#Brochure__share:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__share:last-child {
  margin-right: 0;
}
#Brochure__share:nth-child(4n) {
  margin-right: 0;
}
#Brochure__share:nth-child(4n + 1) {
  clear: left;
}
#Brochure__share__item__title {
  width: calc(99.99% * 4/4 - (30px - 30px * 4/4));
  padding: 15px 0;
  border-top: 1px solid #000;
}
#Brochure__share__item__title:nth-child(n) {
  float: left;
  margin-right: 30px;
  clear: none;
}
#Brochure__share__item__title:last-child {
  margin-right: 0;
}
#Brochure__share__item__title:nth-child(4n) {
  margin-right: 0;
}
#Brochure__share__item__title:nth-child(4n + 1) {
  clear: left;
}
#Brochure__share__item {
  width: calc(99.999999% * 1/4);
}
#Brochure__share__item:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
#Brochure__share__item:last-child {
  margin-right: 0;
}
#Brochure__share__item:nth-child(4n) {
  margin-right: 0;
}
#Brochure__share__item:nth-child(4n + 1) {
  clear: left;
}
#Brochure__share__item img {
  width: 70%;
}
#Brochure__share__title {
  width: calc(99.999999% * 4/4);
}
#Brochure__share__title:nth-child(n) {
  float: left;
  margin-right: 0;
  clear: none;
}
#Brochure__share__title:last-child {
  margin-right: 0;
}
#Brochure__share__title:nth-child(4n) {
  margin-right: 0;
}
#Brochure__share__title:nth-child(4n + 1) {
  clear: left;
}
