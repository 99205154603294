#Quoted
  lost-column 12/12
  background-color #5b5b5b
#Quoted__content
  lost-center 800px
  padding 50px
  @media (max-width: 600px)
    padding: 35px;
  position relative
  quotes: "‘" "’" "“" "”";
  &:before
    font-family Times
    content open-quote
    color white
    font-size 200px
    line-height 200px
    position absolute
    left -20px
  &:after
    font-family Times
    content close-quote
    color white
    font-size 200px
    line-height 200px
    display block
    position absolute
    bottom -85px
    right -20px
p.quoted
  color white
  font-size 14px
  line-height 20px
  padding 30px 0