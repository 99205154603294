#photoAlbum__wrapper
  lost-utility clearfix
  lost-center 980px
  span.title
    lost-column 12/12
    font-size 50px
    text-align: center
    padding: 20px 0 20px 0
  #photoAlbum
    lost-utility clearfix
    lost-center 800px
    height 100%
    .photoAlbum__event
      lost-waffle 1/4 4 20px
      float left
      cursor pointer
      &:hover
        .photoAlbum__event__title
          a
            color myOrange
      img
        width 100%
      .photoAlbum__event__img
        height 150px
        background-size auto 100%
        background-position center center
      .photoAlbum__event__title
        a
          lost-column 1/1
          padding-top: 15px
          font-size 18px
          font-weight 800
          text-align: center
          text-transform: uppercase
          color #333
          &:hover
            color myOrange

      .photoAlbum__event__date
        lost-column 1/1
        padding-top: 2px
        font-size 14px
        text-align: center
        text-transform: uppercase
        color #666