//@lost flexbox flex;
body
  font-family "Comfortaa"
  //lost-utility edit

a
  text-decoration none
  color black
  &:hover, &.active
    color rgba(243,147,0,1)

@import 'colors'
@import 'Header'
@import 'EventAccordion'
@import 'Calendar'
@import 'Footer'
@import 'Quoted'
@import 'event'
@import 'EventPhotos'
@import 'EventVideos'
@import 'ilQuartiereDiGreco'
@import 'photoGallery'
@import 'albumGallery'
@import 'monoSocialIconsFont'
@import 'Brochure'