#Brochure__wrapper
  lost-column 12/12
#Brochure
  lost-center 980px
#Brochure__title
  lost-column 12/12
  text-align center
  font-size 40px
  margin-top: 50px
  color myOrange

#Brochure__1
  lost-column 4/12
  img
    border 1px solid black
    lost-column 4/4

#Brochure__2
  lost-column 4/12
  img
    lost-column 4/4

#Brochure__3
  lost-column 4/12

#Brochure__downloadBtn
  lost-column 4/4
  margin-bottom 20px
  img
    display block
    width 100px
    margin auto

#Brochure__text
  padding 30px

#Brochure__share
  lost-column 4/4
  padding-bottom 15px
  border-bottom: 1px solid black

#Brochure__share__item__title
  lost-column 4/4
  padding 15px 0
  border-top: 1px solid black

#Brochure__share__item
  lost-column 1/4 4 0
  img
    width 70%

#Brochure__share__title
  lost-column 4/4 4 0
