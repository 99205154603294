#event
  lost-center 980px
#event__title
  lost-column 12/12
  font-size 40px
  text-transform: uppercase
  text-align: center
  color myOrange
  padding-top: 40px

#event__image
  margin-top: 20px
  lost-column 12/12
  img
    width:100%

#event__intro
  lost-column 12/12
  text-align center
  padding: 40px 0
  font-weight 800
  font-size 16px

#event__content
  lost-column 12/12
  font-size 14px

#event__sidebar
  padding 20px 0
  border-top 1px solid black
  border-bottom: 1px solid black
  lost-column 3/12
  float left
  text-align center

#event__sidebar__day
  font-size 50px
  color myOrange
  display block
#event__sidebar__monthYear
  text-transform uppercase
  text-align center
  lost-column 3/3
#event__sidebar__time
  text-align center
  lost-column 3/3
#event__sidebar__place
  text-align center
  lost-column 3/3
  border-bottom 1px solid black
  padding-bottom 30px
  margin-bottom 30px

#event__sidebar__shareButtons__wrapper
  lost-center 50%

#event__sidebar__shareButtons
  lost-column 1/1

  a
    lost-waffle 1/2 2 5px
    img
      width 100%

#event__text
  lost-column 9/12
  float left