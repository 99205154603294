footer
  lost-column 12/12
  margin 50px 0 0 0

#footer_container
  lost-center 980px
  margin-top: 50px
  margin-bottom: 20px

#footer1
  lost-column 5/12 12 0

  ul.nav
    lost-column 4/4 4 0
    list-style none
    margin 0
    padding 0
    li
      padding 5px 5px 5px 100px
      @media (max-width: 500px)
        padding 5px
      font-weight 800

  .miniLogo
    lost-column 2.5/5
    margin-top: 80px
    @media (max-width: 500px)
      lost-column 4/4
      text-align center
    img
      width 100%
      @media (max-width: 500px)
        width 50%

  @media (max-width: 500px)
    lost-column 4/4
    text-align center

#footer2
  lost-column 4/12 12 0
  .h1,.h2
    lost-column 4/4
  .h2
    margin-bottom: 0
  .p
    lost-column 4/4
    margin-bottom: 20px

  @media (max-width: 500px)
    lost-column 4/4
    text-align center

#footer3
  lost-column 3/12 12 0
  .h1,.h2
    lost-column 3/3
  .h2
    margin-bottom: 20px
  @media (max-width: 500px)
    lost-column 4/4
    text-align center

.p, i
  margin-bottom 20px

.h1
  font-weight 800
.h2
  font-weight 800

#facebook
  width  30px
  height 30px
  border-radius 100px
  background-image url("/images/footerButtons.png")
  background-size 100% auto
  background-position 0 0
  margin: 10px 0

#list
  top 130px

#search
  top 190px
  background-position 0 100%

#email
  lost-column 3.5/4 4 0
  margin 10px 0
  padding 5px

.subFooter
  border-top 1px solid black
  lost-column 7/7
  img
    display block
    width 200px
    margin 20px auto