#calendarContainer
    lost-column 12/12
    padding-top 25px
    padding-bottom 50px
    background-image url("/images/piatto_min.jpg")
    @media (max-width: 500px)
        background-image none
    background-position center center
    background-size auto 1300px


.Calendar
    lost-center: 500px;
    @media (max-width: 600px)
        lost-center: 80%;
    position:relative;
    &.inTheHeading
        top 80px
        lost-center: 45%
        margin-top: 20px
        @media (max-width: 600px)
            lost-center: 80%;

.Calendar__title
    lost-column 7/7
    text-align center
    font-size 30px
    padding-bottom: 25px
    @media (max-width: 600px)
        font-size 20px

.Calendar__heading
    lost-column 7/7

#Calendar__list
    list-style none
    padding 0
    margin 0
    a:hover
        .Calendar__list_title
            color myOrange

.Calendar__list_element
    lost-column 1/1
    cursor pointer
    padding-bottom 15px

.Calendar__list_date
    color #666
    font-weight 800
    lost-column 1/1
.Calendar__list__info

.Calendar__list_title
    lost-column 1/1
    color #000
    font-weight 800
.Calendar__list_intro
    lost-column 1/1
    color #333
    font-weight 400
.Calendar__body
    lost-column 7/7

.Calendar__monthYear
    float left
    lost-column 5/7
    font-size 30px
    font-weight 800
    padding-bottom 10px
    @media (max-width: 600px)
        font-size 20px
.Calendar__buttons
    float left
    lost-column 2/7

.Calendar__button
    float left
    lost-column 1/2
    font-size 40px
    text-align: center
    cursor pointer
    &:hover
        color myOrange
    @media (max-width: 600px)
        font-size 20px


.Calendar__day
    float left
    position relative
    lost-waffle 1/7 7 0
    text-align center
    line-height 70px
    font-size 45px
    span
        color #666

        &.gotEvents
            color black

        &.today
            color myOrange

    @media (max-width: 600px)
        font-size 30px
        line-height 50px

    for i in 1..42
        &:nth-child(i)
            border-bottom: 1px solid black

    &.heading
        font-size 20px
        padding 1rem 0
        line-height 20px
        border-bottom-width: 0
        border-top: 1px solid black



#list,#search
    position:absolute
    width  40px
    height 40px
    border-radius 100px
    left -15%
    background-image url("/images/calendarButtons.png")
    background-size 100% auto

#list
    top 130px
    background-position 0 0
    cursor pointer

#grid
    position:absolute
    width  40px
    height 40px
    border-radius 100px
    left -15%
    background-image url("/images/calendario.png")
    background-size 100% auto
    top 130px
    background-position 0 0
    cursor pointer

#search
    top 190px
    background-position 0 100%

.Calendar__day__info__item
    padding 10px 0
    border-top 1px solid black
    &:first-child
        border-top-width 0

.Calendar__day__info
    display none
    lost-column 4/2
    position absolute
    background-color white
    padding 10px
    top 0
    left 70px
    z-index 10
    span
        text-align left
        padding 0
        display block
        line-height 20px

#triangle-left
    top 30px
    left:-10px
    position:absolute;
    width 0;
    height 0;
    border-left 20px solid transparent;
    -ms-transform: rotate(-135deg); /* IE 9 */
    -webkit-transform: rotate(-135deg); /* Chrome, Safari, Opera */
    transform: rotate(-135deg);
    border-top 20px solid white;

.Calendar__day__info__category
    text-transform uppercase
    font-size 13px

.Calendar__day__info__title
    font-size 18px
    color rgba(243,147,0,1)
    font-size 800

.Calendar__day__info__datetime
    font-size 14px
    font-size 800