#photoGallery__wrapper
  lost-column 12/12
  //lost-center 920px
  //background-color #333
  //padding 30px

#photoGallery
  lost-utility clearfix
  lost-center 920px
  padding 30px
  height 100%
  background-color #333
  &.first4
    padding 30px 30px 5px 30px
  &.last
    padding 5px 30px 30px 30px
  .photo,.video
    lost-waffle 1/4 4 10px
    @media (max-width: 500px)
      lost-column 4/4
    float left
    background-position center center
    background-size auto 100%
    height 200px
    cursor pointer

#viewer_wrapper_background
  lost-column 12/12
#viewer_wrapper
  lost-utility clearfix
  lost-center 920px
  background-color #333

#viewer
  lost-utility clearfix
  lost-column 1/1
  .photoBig,.videoBig
    lost-column 4.5/7
    @media (max-width: 500px)
      lost-column 7/7
    float left
    img
      lost-column 1/1
  .description
    border-top 1px solid #999
    border-bottom 1px solid #999
    lost-column 2/7
    @media (max-width: 500px)
      lost-column 7/7
    float left
    padding-top: 15px
    span
      text-transform uppercase
      color #999
      font-size 14px
      display block
      &.title,&.date
        font-size 18px
        font-weight 400
        margin-bottom: 10px
        color white
  .close
    lost-column 0.5/7
    float left
    color #999
    font-size 30px
    text-align: right
    cursor pointer
    &:hover
      color myOrange
  .share,.back
    position relative
    border-top 1px solid #999
    padding 15px 0
    color #999
    font-size 14px
    .symbol
      position relative
      right -50px
      display inline
      color myOrange
      font-size 35px
      line-height 0
      margin-left: 10px
      top 5px
      text-align: right
  .back
    i
      position absolute
      right 0
      font-size 20px
      text-align right
      color myOrange
      margin-bottom 0
    a
      color #999
      &:hover
        color myOrange